import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Features from '@solid-ui-blocks/Features/Block06'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Services from '@solid-ui-blocks/Features/Block05'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from '@elegantstack/gatsby-theme-flexiblocks/src/homepage/saas/_styles';
import SupportArticles from '../../components/support/SupportArticles'

const Support = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Support'
        description='We are here to help. Our comprehensive support options include help articles, FAQ page and 24/7 email support.'
        siteUrl='/'
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Divider space='6' />
      <Features content={content['hero']} />
      <Divider space='5' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <SupportArticles content={content['support-articles']} />
      </Container>
      <Services content={content['other-support-options']} />
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query supportBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/support"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Support
